<template>
  <div class="testimonials container">
      <div class="row">
        <div v-for="(item, index) in divs" :key="index" @click="openModal(item)" class="clickable-div col-12 col-md-6"
          data-toggle="modal" data-target="#intro-video">
          <img :src="item.image" :alt="`${item.title} video thumbnail`" class="thumbnail">
          <span class="play-icon">&#9658;</span> <!-- Unicode for play icon -->
          <p>{{ item.title }}</p>
        </div>
      </div>

      <Modal idModal="intro-video">
        <Media :src="modalContent.src" :data-gtm-section="modalContent.title" data-gtm-label="Play video" />
      </Modal>
    </div>
</template>

<script>

import Modal from '@/components/modal/Modal.vue';
import Media from '@/components/ui/Media.vue';

export default {
  name: 'CustomerTestimonials',
  components: {
    Modal,
    Media,
  },
  data() {
    return {
      divs: [
        { title: 'Italian Customers Choose HP', src: 'https://youtu.be/sH--uUWqZKE?feature=shared', image: 'images/why-digital/customers/testimonials/Italian-customers-chose-HP.jpg' },
        { title: 'THIMM’s Digital Journey', src: 'https://youtu.be/mfL2Miil4WA?feature=shared', image: 'images/why-digital/customers/testimonials/digital-journey.jpg' },
        {
          title: 'Smurfit WestRock Delivers with Digital',
          src: 'https://youtu.be/zA19eTwTYHE?feature=shared',
          image: 'images/why-digital/customers/testimonials/smurfit-westRock-delivers-with-digital.jpg',
        },
        { title: 'Introducing Pratt COLOR', src: 'https://youtu.be/_G0BbOHRgw4?feature=shared', image: 'images/why-digital/customers/testimonials/Introducing-Pratt-COLOR.jpg' },
      ],
      modalContent: {
        title: 'Italian Customers Choose HP',
        src: 'https://youtu.be/sH--uUWqZKE?feature=shared',
        image: 'https://via.placeholder.com/150',
      },
    };
  },
  methods: {
    openModal(item) {
      this.modalContent.title = item.title;
      this.modalContent.src = item.src;
      this.modalContent.image = item.image;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/views/why-digital/customers/testimonials';
</style>
