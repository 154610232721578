<template>
  <!-- eslint-disable max-len -->
  <div >
    <div
    class="about-bg active"
    :style="currentSubsection === 1 ? { backgroundImage: 'url(/images/about-us/hp-growth.jpg)' } : {}"></div>
    <div class="container-xl">
      <div class="section__title section__title--digital-value text-center">
        <h2 class="title">
          {{ subSections[currentSubsection].title }}
        </h2>

        <p v-if="subSections[currentSubsection].description" class="text title--small ">
          {{ subSections[currentSubsection].description }}
        </p>
      </div>
        <SubsectionNavLeft
          :currentComponent="subSections[currentSubsection]"
          gtmSubsection="Customers"
          parent="Why Digital"
          :subSections="subSections"
          @click-subnav="changeTab" >
          <component
            :data="getData"
            :is="subSections[currentSubsection].component" />
        </SubsectionNavLeft>
    </div>
    <ShareButton
      :shareLink="`https://admin-corrugated.hp-blueprint.com/assets/977b9c42-305c-43ee-9091-fcbf38e500c0.pdf`"
      shareLabel="Why digital - customers"
      :hash="hashify(`Why digital - customers`)"
      @share-this="shareThis" />
  </div>
</template>

<script>
import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins } from '@/common/mixins';

import ShareButton from '@/components/ui/ShareButton.vue';
import SubsectionNavLeft from '@/layout/SubsectionNavLeft.vue';

import InstallBaseMap from '@/views/why-digital/customers/InstallBaseMap.vue';
import VolumeGrowth from '@/views/why-digital/customers/VolumeGrowth.vue';
import CustomerTestimonials from '@/views/why-digital/customers/CustomerTestimonials.vue';

export default {
  name: 'DigitaValueProposition',
  mixins: [filters, defaultMixins],
  components: {
    ShareButton,
    SubsectionNavLeft,
    InstallBaseMap,
    VolumeGrowth,
    CustomerTestimonials,
  },
  data() {
    return {
      baseURL: endpoints.BASEURL,
      currentSubsection: 0,
    };
  },
  mounted() {
    this.showMainTabs();
  },
  methods: {
    changeTab(payload) {
      this.currentSubsection = payload.id;
    },
  },
  computed: {
    /**
     * The items in the array MUST match the components name files
     * these values are trimmed and passed as components names.
     */
    subSections() {
      return [
        {
          id: 0,
          component: 'InstallBaseMap',
          label: 'Install base map',
          description: '',
          title: 'HP PageWide Industrial Web-fed Packaging Customers​',
        },
        {
          id: 1,
          component: 'VolumeGrowth',
          label: 'Volume growth',
          description: '',
          title: 'HP PageWide Industrial Growth',
        },
        {
          id: 2,
          component: 'CustomerTestimonials',
          label: 'Voice of the Customer',
          description: '',
          title: 'HP PageWide Industrial Packaging customers​',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/about/base.scss';

  .wrapper-sub {
    display: flex;
    align-items: flex-start;
  }

  .section__title--digital-value {
    max-width: 700px;
    margin: 0 auto;
  }

  .wrap-sidetabs {
    margin-top: 32px;
  }
</style>
